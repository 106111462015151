import axios from 'axios';

const BANNERS_MAX_ITERATOR = 10;
const BANNERS_CONTAINER_CLASS = 'arkon-category-banner-container';

export default class Banners {
    constructor(options) {
        this.options = options;

        this.templates = [];
        this.templatesLoaded = false;

        this.templatesController = axios.create({
            baseURL: window.arkonbanners.routes.rest.url
        });

        this.idCategory = window.arkonbanners.id_category;
        this.isRunning = false;
    }

    async run() {
        if(this.isRunning) {
            return;
        }

        this.isRunning = true;
            await this.renderBanners();
        this.isRunning = false;
    }

    async renderBanners() {
        this.deleteBanners();
        this.loadMiniatures();

        if(!this.miniatures || !this.miniatures.length) {
            return;
        }
       
        if(!this.templatesLoaded) {
            await this.loadTemplates();
        }

        if(!this.templates) {
            return;
        }
        
        this.runForMiniatures();
    }

    deleteBanners() {
        const banners = document.querySelectorAll(`.${BANNERS_CONTAINER_CLASS}`);

        for(const banner of banners) {
            banner.remove();
        }
    }

    loadMiniatures() {
        this.miniatures = document.querySelectorAll(`${this.options.productsContainerSelector} > ${this.options.productsSelector}`);
    }

    async loadTemplates() {
        const templatesRequest = await this.templatesController.get('category', {
            params: {
                'id_category' : this.idCategory
            }
        });

        this.templates = templatesRequest.data.templates;
        this.templatesLoaded = true;
    }

    runForMiniatures() {
        for(let index = 1; index <= this.miniatures.length; index++) {
            if(!this.miniatures[index]) {
                return;
            }

            if(this.miniatures[index].classList.contains(BANNERS_CONTAINER_CLASS)) {
                return;
            }

            this.runForMiniature(index);
        }
    }
    
    runForMiniature(index) {
        for(let iterator = 1; iterator <= BANNERS_MAX_ITERATOR; iterator++) {
            if(!this.canRunForIterator(index, iterator)) {
                continue;
            }
            
            this.miniatures[index - 1].outerHTML = this.miniatures[index - 1].outerHTML + this.templates[iterator][0].html;
            this.templates[iterator].push(this.templates[iterator].splice(0, 1)[0]);

            return;
        }
    }

    canRunForIterator(index, iterator) {
        if(Number.parseInt(index) % Number.parseInt(iterator) !== 0) {
            return false;
        }

        if(!this.templates[iterator] || !this.templates[iterator].length) {
            return false
        }

        return true;
    }
}