import Banners from './modules/front/banners';

const BannersContainerSelector = '.product_list';
const BannersProductsSelector = '.ajax_block_product';

const banners = new Banners({
    productsContainerSelector: BannersContainerSelector,
    productsSelector: BannersProductsSelector
});

document.addEventListener('DOMContentLoaded', async () => {
    banners.run();
});

document.addEventListener('BlockLayeredContentReloaded', async () => {
    banners.run();
});

document.addEventListener('InfiniteScrollContentReloaded', async () => {
    banners.run();
});

document.addEventListener('ProductViewReloaded', async () => {
    banners.run();
});


window.arkonbanners = banners;